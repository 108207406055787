import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue")
  },
  {
    path: "/goals",
    name: "Goals",
    component: () => import("../views/Goals.vue")
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: () => import("../views/Gallery.vue")
  },
  {
    path: "/news",
    name: "News",
    component: () => import("../views/News.vue")
  },
  {
    path: "/students",
    name: "Students",
    component: () => import("../views/Students.vue")
  },
  {
    path: "/lawrence",
    name: "Lawrence",
    component: () => import("../views/Lawrence.vue")
  },
  // {
  //   path: "/dev",
  //   name: "Dev",
  //   component: () => import("../views/Dev.vue")
  // },
  // {
  //   path: "/faq",
  //   name: "FAQ",
  //   component: () => import("../views/FAQ.vue")
  // },

  // == Footer Menu ==
  {
    path: "/imprint",
    name: "Imprint",
    component: () => import("../views/Imprint.vue")
  },

  // == 404 Error page ==
  {
    path: "*",
    component: () => import("../views/Error404.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
